// Montserrat starts
@font-face {
    font-family: "Montserrat-Thin";
    src: url("../assets/fonts/Montserrat/Montserrat-Thin.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-Thin.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-Thin.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-Thin.svg#Montserrat-Regular") format("svg");
}

@font-face {
    font-family: "Montserrat-Extra-Thin";
    src: url("../assets/fonts/Montserrat/Montserrat-Thin.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-Thin.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-Thin.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-Thin.svg#Montserrat-Regular") format("svg");
}

@font-face {
    font-family: "Montserrat-Light";
    src: url("../assets/fonts/Montserrat/Montserrat-Light.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-Light.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-Light.svg#Montserrat-Medium") format("svg");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: url("../assets/fonts/Montserrat/Montserrat-Regular.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("../assets/fonts/Montserrat/Montserrat-Medium.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-Medium.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
}

@font-face {
    font-family: "Montserrat-Semi-Bold";
    src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-SemiBold.svg#Montserrat-Regular") format("svg");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("../assets/fonts/Montserrat/Montserrat-Bold.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-Bold.svg#Montserrat-Regular") format("svg");
}

@font-face {
    font-family: "Montserrat-Extra-Bold";
    src: url("../assets/fonts/Montserrat/Montserrat-ExtraBold.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-ExtraBold.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-ExtraBold.svg#Montserrat-Regular") format("svg");
}

@font-face {
    font-family: "Montserrat-Black";
    src: url("../assets/fonts/Montserrat/Montserrat-Black.eot");
    src: url("../assets/fonts/Montserrat/Montserrat-Black.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/Montserrat/Montserrat-Black.woff") format("woff"),
        url("../assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype"),
        url("../assets/fonts/Montserrat/Montserrat-Black.svg#Montserrat-Regular") format("svg");
}

// Montserrat  Ends

// Helvetica Starts

@font-face {
    font-family: "Helvetica-Semi-Bold";
    src: url("../assets/fonts/Helvetica/HelveticaNeueLTStd-Cn.ttf") format("truetype");
}
@font-face {
    font-family: "Helvetica-Bold";
    src: url("../assets/fonts/Helvetica/HelveticaNeueLTStd-MdCn.ttf") format("truetype");
}
@font-face {
    font-family: "Helvetica-Extra-Bold";
    src: url("../assets/fonts/Helvetica/HelveticaNeueLTStd-BdCn.ttf") format("truetype");
}
