@import "./mixins";
@import "./variables.scss";
@include margins;
@include bordercolors;
@include setResponsiveFont;
.cu-label {
  @include roboto(1rem);
  color: $cg-black;
  display: block;
  text-align: left;

  &.inline {
    display: inline;
    color: $cg-black;
    cursor: pointer;
  }

  .mandatory {
    color: $cg-red-primary;
  }
}

.cu-row {
  display: flex;
  flex-direction: column;

  .center {
    justify-content: center;
  }
}

.row-hz {
  display: flex;
  flex-direction: row;

  &.center {
    justify-content: center;
    align-content: center;
  }
}

.row-hz-fit {
  display: flex;
  flex-direction: row;
  margin-top: 5px;

  &.center {
    justify-content: center;
    align-content: center;
  }
}

.cu-field-row {
  margin: 8px 4px !important;
}

.cu-pre-line {
  white-space: pre-line !important;
}

// LeafLet
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}

/*******25-05-2022 BY Orblike******************/

.content_background {
  background-color: var(--ion-content-background-color); ////// content Background color////////
  --background: var(--ion-content-background-color);
}

.bg_white {
  background-color: var(--ion-background-white); ///// background white color//////
  --background: var(--ion-background-white);
}

.bg_gray {
  background-color: var(--ion-color-light-grey); ///// background white color//////
  --background: var(--ion-color-light-grey);
}

.border-full {
  border: 1px solid var(--ion-color-border-grey);
}

.bbgrey {
  border-bottom: 1px solid var(--ion-color-border-grey);
}

.btgrey {
  border-top: 1px solid var(--ion-color-border-grey);
}

.btgrey {
  border-top: 1px solid var(--ion-color-border-grey);
}

.cursor {
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}

.full-width-1 {
  width: 94%;
}

.property_name_width_94 {
  width: 94%;
}

.full-height {
  min-height: 100% !important;
}

.full-ht {
  height: 100%;
}

/**********Full screen Image************/
.full-screen-img {
  height: -webkit-fill-available;
  object-fit: cover;
  width: 100%;
}

.full-screen-img_new {
  // height: -webkit-fill-available;
  height: 100%;
  object-fit: inherit;
  object-position: inherit;
  width: 100%;
}

.fit_obj {
  object-fit: cover !important;
}

.dv {
  display: grid;
}

.dv_col {
  display: flex;
  flex-direction: column;
}

.dh {
  display: flex;
  flex-direction: row !important;
}

.ds {
  align-items: center !important;
  justify-content: flex-start !important;
}

.dc {
  align-items: center !important;
  justify-content: center !important;
}

.ds {
  align-items: center !important;
  justify-content: flex-start !important;
}

.dcs {
  align-self: center !important;
  justify-self: center !important;
}

.txtc {
  text-align: center;
}

.txtl_pro_name {
  text-align: left;
  margin-left: -150px;
}

.spc-bwt {
  justify-content: space-between !important;
  align-items: center !important;
}

.spc-evn {
  justify-content: space-evenly;
  align-items: center;
}

.spc-around {
  align-items: center;
  justify-content: space-around;
}

.ver_items_center {
  display: grid;
  align-content: center;
}

.var_items_end {
  display: grid;
  align-content: end;
}

.txts {
  align-items: center;
  text-align: left;
}

.txts_p10 {
  align-items: center;
  text-align: left;
  padding-left: 10px;
}

.de {
  align-items: center !important;
  justify-content: flex-end !important;
}

.txte {
  align-items: center;
  text-align: end !important;
}

.dfc {
  display: flex;
  align-items: center;
}

.dfjc {
  display: flex;
  justify-content: center !important;
}

.df {
  display: flex;
}

.flex_nowrap_hz {
  display: flex;
  flex-wrap: nowrap;
}

.ver_items_spc_bwt_center {
  align-content: space-between;
  display: grid;
  justify-content: center;
}

.ver_items_spc_bwt_end {
  align-content: space-between;
  display: grid;
  justify-content: flex-end;
}

.ver_items_spc_bwt_st {
  align-content: space-between;
  display: grid;
  justify-content: flex-start;
}

.hor_items_end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.pointer_events_none {
  pointer-events: none;
}

.pos_ab_ver {
  display: grid;
  position: absolute;
  z-index: 1;
}

.pos_ab_hz {
  display: flex;
  position: absolute;
  z-index: 1;
}

.border-none {
  border: none !important;
}

.txt_dec {
  text-decoration: underline;
}

.opac-full {
  opacity: 0.4;
}

.opac-partial {
  opacity: 0.6;
}

.pos_fix {
  position: fixed;
  display: grid;
}

.pos-abs {
  position: absolute;
}

.pos-rel {
  position: relative;
}

.d_block {
  display: block;
}

.d_content {
  display: contents;
}

.select_product_category_modal {
  --height: 50%;
  --width: 100%;
  align-items: flex-end;
  background-color: transparent;
}

.select_voucher_model_sass {
  --height: 100%; 
  --width: 100%;
  align-items: flex-end;
  background-color: transparent;
}

.filter_model_sass {
  --height: 40%;
  --width: 100%;
  align-items: flex-end;
  background-color: transparent;
}

.terms_and_condition_modal {
  --width: 92%;
  --height: auto;
  --max-height: 60%;
  --min-height: 30%;
  background-color: #21212196;

  app-payment-terms-and-conditions {
    align-items: center;
    justify-content: center;
  }

  .modal-wrapper.sc-ion-modal-md {
    border-radius: 6px !important;
  }
}

.text_dec_none {
  text-decoration: none;
}

.text_underline {
  text-decoration: underline;
}

.qr_display_modal {
  --background: #00000087;
  // --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background-color: #00000087;
}

.voucher_qr_display_modal {
  --height: 60%;
  --width: 90%;
  align-items: center;
  --background: transparent;
  --background-color: transparent;
  --backdrop-opacity: var(--ion-backdrop-opactiy, 0.7) !important;
}

.facility_pay_deposit {
  --width: 80%;
  --height: 40%;
  --border-radius: 10px;
}

.decline_modal {
  --height: 60%;
  --width: 90%;
  align-items: center;
  background-color: transparent;
}

app-display-qr-v2 {
  align-items: center !important;
  justify-content: center !important;
}

.welcome_to_homes_modal {
  --width: 77%;
  --height: 99%;
}

.form_list_modal {
  --height: 92% !important;
  --width: 85% !important;
  align-items: flex-end !important;
  justify-content: center;
}

.auto_capitalize {
  text-transform: uppercase !important;
}

.bw_no_touch {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  pointer-events: none !important;
}

.text_decoration_none {
  text-decoration: none !important;
}

app-skeletons {
  width: 100%;
}

.full_modal {
  --width: 100%;
  --height: 100%;
}

.whats_app_color {
  color: #4fce5d !important;
}

.modal_background_opc {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0) !important;
}

.renewal_remainder_modal_wrapper {
  --width: 75%;
  --min-height: 22%;
  --height: fit-content;
  --max-height: 55%;
  --border-radius: 20px;
}

.welcome_modal {
  --height: 60%;
  --width: 90%;
  align-items: center;
  background-color: transparent;
  --border-radius: 20px;
}

.notification-popOver-list {
  --width: fit-content;
  --min-width: 175px;
  --height: fit-content;
  --min-height: 50px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  .popover-content {
    width: 50% !important;
  }
}

.tabs_card_modal {
  // --min-width: 75%;
  // --width: auto;
  // --max-width: 90%;
  // --height: auto;
  // --max-height: 60%;
  // --min-height: 20%;
  // align-items: center;
  // justify-content: center;
  background: #e9ecf0;
  background-color: #e9ecf0;
  --background: transparent;
  --background-color: transparent;
  // --border-radius: 4px;

  app-tabs-card {
    background-color: transparent;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.propper_property_modal {
  --width: 100%;
  --height: 50%;
  align-items: flex-end;
  background: transparent;
  background-color: transparent;
  --background: transparent;
  --background-color: transparent;

  app-my-home-property-list {
    background: transparent;
    background-color: transparent;
    --background: transparent;
    --background-color: transparent;
  }
}

.promoter_bank_modal_scss {
  --width: 100%;
  --min-height: 40%;
  --height: 60%;
  align-items: flex-end;
  --border-radius: 16px;
}

.promoter_terms_and_conditions_modal_scss {
  --width: 100%;
  --min-height: 40%;
  --max-height: 75%;
  align-items: flex-end;
  --border-radius: 16px;
}

.pm_reject_popover {
  --width: 75%;
  --min-height: 25%;
  --height: fit-content;
  --max-height: 55%;
  --border-radius: 20px;
  background-color: #000000bf;
}

.req_accept_popover {
  --width: 75%;
  --min-height: 15%;
  --height: fit-content;
  --max-height: 55%;
  --border-radius: 20px;
  background-color: #000000bf;
}

.promo_apply {
  --width: 85%;
  --min-height: 5%;
  --height: fit-content;
  --max-height: 55%;
  --border-radius: 12px;
}
.modal_height_25 {
  --height: 25%
}

.member_reward_points_modal_scss {
  --width: 80%;
  --height: fit-content;
  --border-radius: 14px;
}

.promo_save_popover {
  --width: 80%;
  --min-height: 13%;
  --height: fit-content;
  --max-height: 13%;
  --border-radius: 15px;
}
