/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~animate.css/animate.min.css";

@import url("https://fonts.googleapis.com/css?family=Baloo+Da+2|Open+Sans|Oswald|Roboto|Crete+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto Sans SC");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css");

@import "~@ng-select/ng-select/themes/default.theme.css";

@import "./theme/mixins";
@import "./theme/fonts.scss";
@import "./theme/custom.scss";

//Leaflet and OpenStreetMap css
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

$colors: (
  primary: #387ef5,
  secondary: #32db64,
  danger: #f53d3d,
  light: #f4f4f4,
  dark: #222,
  vibrant: rebeccapurple,
  bright: #ffc125,
  white: #ffffff,
  proppyMain: #6ab5bb,
  tabColor: #9dc2c4,
);

:root {
  .popover-content {
    //left: 0 !important;
    width: 80%;
  }
}

.modal {
  --height: 60%;
  align-items: flex-end;
}

.modalProppyCaresInfo {
  --height: 85%;
  --width: 85%;
  align-items: center;
  background-color: transparent;
}

.modalMarketplaceInfo {
  --height: 85%;
  --width: 85%;
  align-items: center;
  background-color: transparent;
}

.wizard-model {
  --height: 100%;
  --width: 65%;
  align-items: flex-end;
  background-color: transparent;
  justify-content: flex-end;
}

.modalalert {
  --height: calc(100% - 10rem);
  --width: 85%;
  align-items: center;
  background-color: transparent;
}

.modalMarketPlaceMediaPopup {
  --height: 85%;
  align-items: center;
  background-color: transparent;

  .closeBtn {
    position: absolute;
    //top: -20px;
    left: 0px;
    z-index: 99999;
  }
}

.hashtag-grad {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.btn-grad {
  margin: 5px;
  padding: 10px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
}

.btn-grad-notfull {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.btn-grad-notfull {
  margin: 5px;
  padding: 10px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  font-family: "Montserrat";
  font-weight: 900;
}

.btn-tab {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.btn-tab {
  margin: 5px;
  padding: 5px 10px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
}

.btn-tab-inactive {
  background-image: linear-gradient(to right, #fff 0%, #fff 51%, #fff 100%);
}

.btn-tab-inactive {
  margin: 5px;
  padding: 5px 10px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(33, 73, 158);
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
}

// tom 20/10/2021
.icon-btn-grad {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.icon-btn-grad {
  margin: 5px;
  padding: 10px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

.icon-btn-grad-small {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.icon-btn-grad-small {
  padding: 2px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  font-size: 0.7rem;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

.icon-btn-grad-medium {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.icon-btn-grad-medium {
  margin: 6px;
  padding: 9px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

.icon-btn-grads {
  margin: 5px;
  padding: 10px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  color: rgb(138, 9, 9);
  background-color: rgb(196, 13, 13);

  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

// orblike 04/01/2022

.icon-btn-grad-outlinered {
  margin: 5px;
  padding: 10px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  // box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  color: rgb(138, 9, 9);
  border: 1px solid red;
  background-color: white;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: red !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: red !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

//  orblike 04/01/2022
.icon-btn-grad-outlineblue {
  margin: 5px;
  padding: 10px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  // box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  color: #426ab3;
  border: 1px solid #426ab3;
  background-color: white;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: #426ab3 !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: #426ab3 !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

.icon-btn-grad-notfull {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.icon-btn-grad-notfull {
  margin: 5px;
  padding: 10px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  font-family: "Montserrat";
  font-weight: 900;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

.icon-btn-tab {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
}

.icon-btn-tab {
  margin: 5px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }
}

.icon-btn-tab-inactive {
  background-image: linear-gradient(to right, #fff 0%, #fff 51%, #fff 100%);
}

.icon-btn-tab-inactive {
  margin: 5px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  //  background-size: 200% auto;
  color: rgb(33, 73, 158);
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: "Montserrat";
  font-weight: 900;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: rgb(33, 73, 158) !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: rgb(33, 73, 158) !important;
    text-transform: capitalize !important;
  }
}

//end

.workingonit {
  font-family: "Montserrat";
  font-size: 1.3em;
  font-weight: 900;
  display: block;
  margin-bottom: 10px;
}

.staytune {
  font-family: "Montserrat";
  font-size: 0.9em;
  font-weight: 300;
  display: block;
  color: rgb(33, 73, 158);
  margin-bottom: 30px;
}

.btn50Percent {
  width: 80%;
  align-items: center;
  border: 1px solid #000;
}

// @font-face {
//   font-family: 'Montserrat-Medium';
//   src: url('./assets/fonts/Montserrat-Medium.ttf');
// }

// @font-face {
//   font-family: 'Montserrat-SemiBold';
//   src: url('./assets/fonts/Montserrat-SemiBold.ttf');
// }

// @font-face {
//   font-family: 'NotoSansSC-Bold';
//   src: url('./assets/fonts/NotoSansSC-Bold.otf');
// }

// @font-face {
//   font-family: 'NotoSansSC-Medium';
//   src: url('./assets/fonts/NotoSansSC-Medium.otf');
// }

// ion-content{
//   --color: #00000;
//   --ion-background-color:#ffffff;
// }

.bold {
  font-weight: bold;
}

ion-content {
  font-family: "Montserrat";
}

.ios.platform-mobile ion-menu.menu-enabled,
.ios.platform-mobile ion-menu.menu-enabled,
.ios.platform-mobile ion-modal.show-page {
  margin-top: constant(safe-area-inset-top); // for ios 11.1
  margin-top: env(safe-area-inset-top); // for ios 11.2 and onwards
  margin-bottom: env(safe-area-inset-bottom);
  height: calc(100% - constant(safe-area-inset-top));
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

ion-toolbar {
  //--background: #6ab5bb;
  font-family: "Montserrat";
  font-size: 1em;
  color: #fff;
  vertical-align: middle;
  //height:39px;
  //--background: url('./assets/toolbarbg.png') repeat-x;
  // --background: url('./assets/uiv3/home_header_bg_350.png') repeat-x;
  // --background: url("./assets/uiV3/icon/about-page/header_bg_250.png") repeat-x;
  --background: #071344;
  //border:none;
  text-overflow: unset;
  white-space: unset;
}

.pageTitle {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 1.1em;
  color: #6ab5bb;
  line-height: 35px;
}

.pageTitleSmall {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 0.9em;
  color: #6ab5bb;
  line-height: 35px;
}

.pageTitleSub {
  font-family: "Noto Sans SC";
  font-size: 0.9em;
  font-weight: bold;
}

.pageTitleSubSmall {
  font-family: "Noto Sans SC";
  font-size: 0.7em;
  font-weight: bold;
}

.pEn {
  color: #6ab5bb;
  font-family: "Montserrat";
  font-size: 0.9em;
  line-height: 30px;
}

.pZh {
  font-family: "Noto Sans SC";
  font-size: 0.9em;
  line-height: 30px;
}

body {
  background-color: #1b517f;
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat-Medium";
}

// .myrealty {
//   font-family: 'Roboto', sans-serif;
//   font-size:30px;
//   line-height: 0px;
// }

// .myrealtyForm {
//   font-family: 'Oswald', sans-serif;
//   font-size:30px;
// }

// .myrealtySavings {
//   font-family: 'Oswald', sans-serif;
//   font-size:20px;
// }

// .ribbon {
//   font-size: 16px !important;
//   /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */

//   width: 90%;

//   position: relative;
//   background: #ba89b6;
//   color: #fff;
//   text-align: center;
//   padding: 1em 2em; /* Adjust to suit */
//   margin: 2em auto 3em; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
//  }
//  .ribbon:before, .ribbon:after {
//   content: "";
//   position: absolute;
//   display: block;
//   bottom: -1em;
//   border: 1.5em solid #986794;
//   z-index: -1;
//  }
//  .ribbon:before {
//   left: -2em;
//   border-right-width: 1.5em;
//   border-left-color: transparent;
//  }
//  .ribbon:after {
//   right: -2em;
//   border-left-width: 1.5em;
//   border-right-color: transparent;
//  }
//  .ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
//   content: "";
//   position: absolute;
//   display: block;
//   border-style: solid;
//   border-color: #804f7c transparent transparent transparent;
//   bottom: -1em;
//  }
//  .ribbon .ribbon-content:before {
//   left: 0;
//   border-width: 1em 0 0 1em;
//  }
//  .ribbon .ribbon-content:after {
//   right: 0;
//   border-width: 1em 1em 0 0;
//  }

//  .ribbonBuy {
//   width: 48%;
//   height: 90px;
//   position: relative;
//   float: left;
//   margin-bottom: 30px;

//   text-transform: uppercase;
//   color: white;
// }

// .ribbonBuy:nth-child(even) {
//   margin-right: 4%;
// }
// @media (max-width: 500px) {
//   .ribbonBuy {
//     width: 100%;
//   }
//   .ribbonBuy:nth-child(even) {
//     margin-right: 0%;
//   }
// }

// .ribbon3 {
//   // width: 90%;
//   height: 50px;
//   line-height: 50px;
//   padding-left: 15px;
//   position: absolute;
//   left: -8px;
//   top: 20px;
//   background: #59324C;
// }
// .ribbon3:before, .ribbon3:after {
//   content: "";
//   position: absolute;
// }
// .ribbon3:before {
//   height: 0;
//   width: 0;
//   top: -8.5px;
//   left: 0.1px;
//   border-bottom: 9px solid black;
//   border-left: 9px solid transparent;
// }
// .ribbon3:after {
//   height: 0;
//   width: 0;
//   right: -14.5px;
//   border-top: 25px solid transparent;
//   border-bottom: 25px solid transparent;
//   border-left: 15px solid #59324C;
// }

.lineBreak {
  white-space: pre-line;
}

.cart-modal {
  --height: 80%;
  --border-radius: 10px;
  padding: 25px;
}

.mortgage-modal {
  --height: 80%;
  --border-radius: 10px;
  padding: 5px;
}

.butlerModal {
  background: transparent !important;
  --background: transparent !important;
  align-items: self-end;
  --max-width: 85%;
  // height: 80%;
  //opacity: 0.3 !important;

  .modal-wrapper {
    width: 90%;
    height: 90%;
  }
}

.modal-wrapper {
  background: transparent !important;
}

.activeNo {
  color: red;
  font-weight: bold;
}

.activeYes {
  color: green;
  font-weight: bold;
}

/*
CSS for all forms
*/

ion-title {
  color: #fff;
  font-size: 15px;
}

.content {
  --background: white;
  background-color: white !important;

  .label {
    text-align: left;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }

  .alignment {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .row {
    color: black;
    margin: 1rem;

    .label {
      text-align: left;
      font-size: 0.7rem;
      font-family: "Montserrat", sans-serif;
      margin-bottom: 0.3rem;
      font-weight: 700;
    }

    .input {
      appearance: none;
      font-size: 0.7rem;
      --placeholder-color: gray;
      color: black;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      border: 0.5px solid #cccccc;
    }

    .dropdown {
      appearance: none;
      font-size: 0.7rem;
      color: gray;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      border: 0.5px solid #cccccc;
    }
  }
}

.footer {
  .toolbar {
    --background: white;
    background-color: white;

    .btn {
      margin: 1rem;
    }
  }
}

ion-title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.back {
  font-size: 20px;
  margin: 1rem;
}

.header {
  .toolbar {
    --background: white;
    color: black;

    .app-title {
      --color: black;
      font-size: 1.3rem;
      font-family: "Open Sans";
      font-weight: 800;
      font-style: bold;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: subpixel-antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin: 1rem 0;
    }
  }
}

.content {
  --background: white;
  background-color: white !important;

  .options-tab-layout {
    --background: white;
    border: gray 0.1rem;
    margin-bottom: 0.1rem;
    margin-top: 0.1rem;

    .filterSortItem {
      font-size: 1rem;
      font-family: "Open Sans";
      font-weight: normal;
      --color: black;
      --color-selected: black;
    }
  }

  .count-info-row {
    font-size: 1rem;
    font-family: "Open Sans";
    font-style: bold;
    font-weight: bold;
    margin: 0.5rem;
    text-align: center;

    span {
      margin-left: 0.2rem;
      font-weight: lighter;
    }
  }

  .list {
    --ion-background-color: #f4f4f4 !important;

    .row {
      width: 100%;
      background: white !important;
      margin: 0 !important;

      .top {
        .card-img {
          height: 10rem;
          object-fit: cover;
          width: 100%;
          z-index: 1;
        }

        .inside-card {
          --background: white;
          background-color: white;
          height: 1rem;
          width: 2rem;
          position: absolute;
          right: 0.5rem;
          font-size: 2rem;
          color: black;
          bottom: 0.5rem;
          z-index: 2;
        }

        .camera-btn {
          position: absolute;
          right: 1.2rem;
          font-size: 0.8rem;
          color: black;
          bottom: 0.5rem;
          z-index: 2;
        }

        .text {
          position: absolute;
          right: 0.7rem;
          font-size: 0.7rem;
          color: black;
          bottom: 0.5rem;
          z-index: 2;
        }
      }

      .bottom-text-layout {
        margin-left: 0.5rem;

        .whishlist-btn {
          font-size: 1.5rem;
          color: black;
          z-index: 2;
        }

        .label {
          color: black;
          font-size: 0.8rem;
          font-family: "Open Sans";
          font-weight: 300;
          margin: 0.5rem;
        }

        .main-text {
          font-size: 1.2rem;
          font-family: "Open Sans";
          font-weight: bolder;
          font-style: bold;
          color: black;
        }

        .sub-text {
          font-size: 1rem;
          font-family: "Open Sans";
          font-weight: 400;
          color: black;

          span {
            display: block;
            color: gray;
            font-size: 0.8rem;
          }
        }

        .text {
          display: block;
          color: gray;
          font-size: 0.8rem;
          font-family: "Open Sans";
          margin-bottom: 0.3rem;
        }

        .icons {
          width: 1rem;
          height: 1rem;
          margin: auto 0.3rem auto 0;
        }
      }
    }

    .card {
      --background: white;
      background-color: white !important;

      .card-img {
        height: 10rem;
        object-fit: cover;
        position: relative;
        z-index: 1;
      }

      .whishlist-btn {
        position: absolute;
        right: 0.5rem;
        font-size: 1.5rem;
        color: #2f8dcd;
        top: 0.5rem;
        z-index: 2;

        .selected {
          color: #2f8dcd;
        }
      }

      .label {
        color: black;
      }
    }
  }
}

.floating-buttons {
  width: 20rem;
  height: 5rem;
  margin: 0 auto;
  position: fixed;
  bottom: 3rem;
  left: 0;
  right: 0;
  z-index: 1;
  align-items: center;
  justify-items: center;

  .floating-buttons-layout {
    display: flex;
    flex-direction: row;

    .floating-button {
      color: #466582;
      --background: #e1f3ff;
    }
  }
}

// agm-map {
//     height: 300px;
// }

.icon {
  margin: auto 0.3rem auto 0;
  color: gray;
  font-size: 1rem;
}

.img-icon {
  width: 1rem;
  height: 1rem;
}

ion-app.cameraView ion-nav {
  opacity: 0;
}

ion-app.cameraView,
ion-app.cameraView ion-content,
ion-app.cameraView .nav-decor {
  background: transparent url("/assets/img/camera_overlay.png") !important;
  //background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
}

ion-header {
  background-color: #e1e9ec !important;
}

.leaflet-tooltip {
  position: absolute;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgb(155, 0, 0);
  border: 0px solid rgb(0, 0, 0);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -10px;
  border-top-color: rgb(155, 0, 0);
}

.blackandwhiteImg {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.my-HashTagPrompt-class {
  .alert-wrapper {
    padding-bottom: 0px;
  }

  .alert-head.sc-ion-alert-ios {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .alert-input.sc-ion-alert-ios {
    margin-top: 2px;
  }

  .alert-message.sc-ion-alert-ios,
  .alert-input-group.sc-ion-alert-ios {
    color: var(--ion-color-step-550, #f13c1c);
    padding-bottom: 3px;
  }

  .alert-title.sc-ion-alert-md {
    padding-top: 6px;
    font-size: 18px;
  }

  .alert-head.sc-ion-alert-md {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .alert-input.sc-ion-alert-md {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 6px;
  }

  .alert-message.sc-ion-alert-md,
  .alert-input-group.sc-ion-alert-md {
    color: var(--ion-color-step-550, #f13c1c);
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

//c360 global styles start.........................
.c360-background-color {
  --background: #c5252b !important;
}

.c360-footer {
  background-color: #c5252b;
  --background: #c5252b;
  padding: 0.5rem;

  .c360-footer-label {
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: white;
  }

  .c360-disclaimer-label {
    font-size: 0.7rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: white;
    text-align: center;
  }
}

.c360-footer {
  background-color: #c5252b;
  --background: #c5252b;
  padding: 0.5rem;

  .c360-footer-label {
    font-size: 0.7rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: white;
  }

  .c360-disclaimer-label {
    font-size: 0.6rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: white;
    text-align: center;
  }
}

//end..............................................

//about proppy.....................................
.menu-toolbar {
  --background: url("./assets/uiV3/icon/about-page/burger_slide_topbg.png")
    repeat-x;
}

.new-toolbar {
  --background: url("./assets/uiV3/icon/about-page/header_bg_250.png") repeat-x;
}

//end...............................................

//menu button color fix.................
ion-menu-button {
  color: white;
}

//end...................................

.register-message {
  font-weight: 5rem;
}

.basic-alert {
  .alert-wrapper.sc-ion-alert-md,
  .alert-wrapper.sc-ion-alert-ios {
    align-items: center;

    .alert-head.sc-ion-alert-md,
    .alert-message.sc-ion-alert-md {
      text-align: center;
    }
  }
}

.nm {
  font-size: 2rem;
  text-align: center;
}

.bn {
  font-weight: bold;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  padding-top: 20px;
  color: black;
}

.cv {
  font-size: 0.7rem;
  font-weight: bold;
  border: 1.5px solid #426ab3;
  border-radius: 1rem;
  color: #426ab3;
  font-family: "Open Sans";
  padding: 0.5rem;
}

//Tom 06/01/2022

.confirm-transfer-modal {
  --height: 70%;
  align-items: flex-end;

  .modal-wrapper {
    border-radius: 25px 25px 0 0;
  }
}

//end
// Orblike 07/01/2022

.icon-pro-tab {
  background-image: linear-gradient(
    to right,
    #00669b 0%,
    #5d3881 51%,
    #00669b 100%
  );
  margin: 5px 5px 0 5px;
  padding: 0.7rem;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  font-family: "Montserrat";
  font-weight: 900;
  color: white;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    font-size: 1.3rem !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: white !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

.icon-pro-tab-inactive {
  background: lightgray;
  margin: 5px 5px 0 5px;
  padding: 0.7rem;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  font-family: "Montserrat";
  font-weight: 900;
  color: black;
  --background: transparent;

  ion-icon {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    font-size: 1.3rem !important;
    color: black !important;
    text-transform: capitalize !important;
  }

  ion-label {
    font-family: "Montserrat" !important;
    font-weight: 900 !important;
    color: black !important;
    text-transform: capitalize !important;
  }

  img {
    height: 1rem;
    margin-right: 0.3rem;
  }
}

app-uitext-field {
  width: 100% !important;
}

app-pro-cb-dropdown {
  width: 100% !important;
}

app-pro-slider {
  width: 100% !important;
}

app-uigrid {
  width: 100% !important;
}

.text_ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.multi_line_ellipse {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  // height: 43px;
  // margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ck-editor__editable_inline {
  height: 100px !important;
  overflow-y: scroll;
  p {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}
.modal-transparency {
  --background: transparent;
}
.custom-pagination-bullets,
.swiper-container-horizontal > .swiper-pagination-bullets {
  // bottom: 0;
  // left: 0;
  width: 100%;
}

.popover-visitor-vehicles {
  --width: 45% !important;
  --max-width: 45% !important;
  ion-label {
    color: #e4a3e5 !important;
    font-weight: 600;
  }
}
.my-payment-deposit-modal-css .modal-wrapper {
  height: 35%;
  display: block;
  border-radius: 10px;
  border: 4px solid #30c0de;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 55px;
}

.modal-bg-black {
  background-color: rgba(0, 0, 0, 0.1);
  --background: rgba(0, 0, 0, 0.1);
}

.modal_gems {
  --height: calc(100% - 1rem);
}
:root {
  .preloved_popover-content {
    width: 75% !important;
  }
}
.preloved_probtn {
  background-image: linear-gradient(90deg, #00669b 0, #5d3881 51%, #00669b);
  margin: 5px;
  padding: 5px;
  height: 43px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  display: block;
  font-family: Montserrat;
  font-weight: 900;
  color: #fff;
  --background: transparent;
}
.fab_icon_bg {
  background-color: red !important;
}

.scroll_bar_col {
  --scroll-bar-background: white;
  --scroll-bar-background-active: #3b499f;
}
:root {
  .billing_notifications_popover {
    width: 100% !important;
    .popover-content {
      width: 55% !important;
      left: calc(117.75px - var(--ion-safe-area-right, 0px)) !important;
      margin-top: -1px !important;
    }
    .popover-arrow.sc-ion-popover-ios::after {
      display: block !important;
      left: 3px;
      // top: 3px;
      border-radius: 3px;
      position: absolute;
      width: 14px;
      height: 14px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      background: var(--background);
      content: "";
      z-index: 10;
      background-color: red !important;
    }
  }
}
ion-toast.custom-toast {
  top: 100px;
  left: 38%;
  width: 9em;
  text-align: center;
  --border-radius: 0px;
}
.contact_delete_modal {
  --height: 25%;
  --width: 85%;
  --border-radius: 9px !important;
  align-items: center;
  background-color: transparent;
}
.contact_single_add_modal {
  --height: 29%;
  --width: 85%;
  --border-radius: 9px !important;
  align-items: center;
  background-color: transparent;
}
.contact_group_modal {
  --height:30%;
  --width: 85%;
  --border-radius: 9px !important;
  align-items: center;
  background-color: transparent;
}
.contact_beep_modal{
  --height:30%;
  --width: 85%;
  --border-radius: 9px !important;
  align-items: center;
  background-color: transparent;
}
.group-contact-modal{
  --width:100%;
  --height:100%;
  background: transparent !important;
}
.group-contact_delete_modal {
  --height: 25%;
  --width: 85%;
  --border-radius: 9px !important;
  align-items: center;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.6) !important;
}
.select_voucher_model_global {
  --height: 60%;
  --width: 100%;
  align-items: flex-end;
  background-color: transparent;
  --backdrop-opacity: var(--ion-backdrop-opactiy, 0.7) !important;
  .modal-wrapper {
    border-radius: 20px 20px 0 0;
  }
}
.pm_tracker_receive_confirm_modal {
  background-color: #000000a3;
}

// Map Cluster UI
.marker-cluster-container {
  background-color: #1D7AFC;
  background:  #1D7AFC;
  border-radius: 50% !important;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 4px solid white;
  img {
    width: 40px;
    height: 40px;
  }
  span {
    color: white;
    @include applyFont($montserrat-medium, bold, 15px, $normal-font-style)
  }
}
.marker_cluster_event_container{
  background-color: #EF7D00 !important;
  background: #EF7D00;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 15px !important;
  gap: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  flex-direction: row !important;
  white-space: pre !important;
  color: white !important;
  border: 1px solid #EC6608 !important;
  border-radius: 20px !important;
  height: 12px !important;
  .event_calender{
    background-color: white !important;
    background: white !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
  }
  span {
    color: white;
    @include applyFont($montserrat-medium, bold, 15px, $normal-font-style)
  }
}

/* leaflet-map.component.css */
.leaflet-control-container {

  .leaflet-control-scale {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .leaflet-control-scale-line {
    width: fit-content !important;
    font-size: 12px;
    /* Custom font size */
    color: #333;
    /* Custom text color */
    background-color: transparent;
    /* Custom background color */
    background: transparent;
    border: none;
  }

  .leaflet-control-scale-line:last-child {
    border: 0.75px dashed #000000;
    border-top: none;
    color: #fff;
    height: 5px;
    margin-top: 1px;
    width: 45px !important;
    font-size: 0px;
  }
}
.dropdown_arr_ {
  width: 0.8 !important;
  height: 0.6463rem !important;
}
.sentence_wrp{
  word-break: normal;
  white-space: normal;
}
