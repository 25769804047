/** App Custom Varaiables */
$bdr-rds-sm: 5px;
$bdr-rds-rounded: 20px;
$cg-white: #fff;
$cg-black: #000;
$cg-blue-tertiary-lighten: #082032;
$cg-blue-secondary-lighten: #0162e826;
$cg-blue-tertiary-darken: #559bcf;
$cg-red-primary: #ff374a;
$sizes: 35;

/** App Custom Mixins */
@mixin roboto($font-size) {
  font-family: "Roboto-Regular", "sans-serief";
  font-weight: 400;
  font-style: normal;
  font-size: $font-size;
}

/** App Custom Mixins */
$montserrat: "Montserrat-Regular";
$montserrat-light: "Montserrat-Light";
$montserrat-medium: "Montserrat-Medium";
$montserrat-semibold : "Montserrat-Semi-Bold";
$montserrat-bold: "Montserrat-Bold";
$montserrat-extrabold: "Montserrat-ExtraBold";
$normal-font-style: normal;

@mixin applyFont($font-name, $font-weight, $font-size, $font-style) {
  font-family: $font-name;
  font-weight: $font-weight;
  font-style: $font-style;
  font-size: $font-size;
  letter-spacing: normal;
}

@mixin margins {
  @for $i from 0 through $sizes {
    $margin: $i * 0.25rem;

    body {
      .pam#{$i} {
        margin: $margin;
      }

      .paml#{$i} {
        margin-left: $margin;
      }

      .pamr#{$i} {
        margin-right: $margin;
      }

      .pamt#{$i} {
        margin-top: $margin;
      }

      .pamb#{$i} {
        margin-bottom: $margin;
      }

      .pamx#{$i} {
        margin-left: $margin;
        margin-right: $margin;
      }

      .pamy#{$i} {
        margin-top: $margin;
        margin-bottom: $margin;
      }

      .pap#{$i} {
        padding: $margin !important;
      }

      .papl#{$i} {
        padding-left: $margin !important;
      }

      .papr#{$i} {
        padding-right: $margin !important;
      }

      .papt#{$i} {
        padding-top: $margin !important;
      }

      .papb#{$i} {
        padding-bottom: $margin !important;
      }

      .papx#{$i} {
        padding-left: $margin !important;
        padding-right: $margin !important;
      }

      .papy#{$i} {
        padding-top: $margin !important;
        padding-bottom: $margin !important;
      }

      .wdth#{$i} {
        width: $margin !important;
      }

      .hght#{$i} {
        height: $margin !important;
      }

      .pafl#{$i} {
        @include applyFont($montserrat-light, lighter, $margin, $normal-font-style);
      }

      .pafn#{$i} {
        @include applyFont($montserrat, normal, $margin, $normal-font-style);
      }

      .pafsb#{$i} {
        @include applyFont($montserrat-semibold, 800, $margin, $normal-font-style);
      }

      .pafb#{$i} {
        @include applyFont($montserrat-medium, 600, $margin, $normal-font-style);
      }

      .pafeb#{$i} {
        @include applyFont($montserrat-bold, 900, $margin, $normal-font-style);
      }

      // Changes
      .fml#{$i} {
        @include applyFont($montserrat-light, lighter, #{$i}px, $normal-font-style);
      }

      .fmn#{$i} {
        @include applyFont($montserrat, normal, #{$i}px, $normal-font-style);
      }

      .fmb#{$i} {
        @include applyFont($montserrat-medium, bold, #{$i}px, $normal-font-style);
      }

      .fmsb#{$i} {
        @include applyFont($montserrat-semibold, 800, #{$i}px, $normal-font-style);
      }

      .fmeb#{$i} {
        @include applyFont($montserrat-bold, bolder, #{$i}px, $normal-font-style);
      }

      .brrem#{$i} {
        border-radius: $margin !important;
      }

      .br#{$i} {
        border-radius: #{$i}px !important;
      }

      .bblr#{$i} {
        border-bottom-left-radius: #{$i}px !important;
      }

      .bbrr#{$i} {
        border-bottom-right-radius: #{$i}px !important;
      }

      .btrr#{$i} {
        border-top-right-radius: #{$i}px !important;
      }

      .btlr#{$i} {
        border-top-left-radius: #{$i}px !important;
      }

      .wtpx {
        width: #{$i}px !important;
        ;
      }

      .htpx {
        height: #{$i}px !important;
      }

      .mle#{$i} {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        // line-height: 1;
        -webkit-line-clamp: #{$i};
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@mixin setResponsiveFont {
  @for $i from 0 through 1000 {
    $font-size: $i * 0.10rem;

    // font rem for responsive
    // .fmrl#{$i} {
    //   @include applyFont($montserrat-light, lighter, $font-size, $normal-font-style);
    // }

    // .fmrn#{$i} {
    //   @include applyFont($montserrat, normal, $font-size, $normal-font-style);
    // }

    // .fmrm#{$i} {
    //   @include applyFont($montserrat-medium, 500, $font-size, $normal-font-style);
    // }

    // .fmrb#{$i} {
    //   @include applyFont($montserrat-bold, bold, $font-size, $normal-font-style);
    // }

    // .fmreb#{$i} {
    //   @include applyFont($montserrat-bold, bolder, $font-size, $normal-font-style);
    // }

    // Montessarat Normal

    .fmrt#{$i} {
      @include applyFont($montserrat, 100, $font-size, $normal-font-style );
    }

    .fmret#{$i} {
      @include applyFont($montserrat, 200,$font-size, $normal-font-style );
    }

    .fmrl#{$i} {
      @include applyFont($montserrat, 300, $font-size, $normal-font-style );
    }

    .fmrn#{$i} {
      @include applyFont($montserrat, 400, $font-size, $normal-font-style);
    }

    .fmrm#{$i} {
      @include applyFont($montserrat, 500, $font-size, $normal-font-style);
    }

    .fmrsb#{$i} {
      @include applyFont($montserrat, 600, $font-size, $normal-font-style);
    }

    .fmrb#{$i} {
      @include applyFont($montserrat, 700, $font-size, $normal-font-style);
    }

    .fmreb#{$i} {
      @include applyFont($montserrat, 800, $font-size, $normal-font-style);
    }

    .fmr_black#{$i} {
      @include applyFont($montserrat, 900, $font-size, $normal-font-style);
    }
  }
}

$color_names :('default', 'troyblue', 'gold', 'gray', 'darkBlue', 'green', 'white', 'darkText', 'danger', 'warning', 'cd');
$border-colors: (
  var(--ion-content-background-color),
  var(--ion-default-text-color),
  var(--ion-price-text-color),
  var(--ion-color-border-grey),
  var(--ion-default-bold-color),
  var(--ion-default-green-color),
  var(--ion-background-white),
  var(--ion-custom-dark-text-color),
  red,
  var(--ion-color-warning),
  var(--custom-default-border-color)
);

@mixin bordercolors {

  @for $i from 1 to length($color_names)+1 {

    .color-#{"" + nth($color_names, $i)} {
      color: nth($border-colors , $i) !important;
    }

    .background-#{"" + nth($color_names, $i)} {
      background-color: nth($border-colors, $i) !important;
      --background: nth($border-colors, $i) !important;
    }

    .border-#{nth($color_names, $i)} {
      border: 1px solid nth($border-colors, $i) !important;
    }

    // DASHED BORDER CSS START

    .dashed-border-#{nth($color_names, $i)} {
      border: 1px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-left-#{nth($color_names, $i)} {
      border-left: 1px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-right-#{nth($color_names, $i)} {
      border-right: 1px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-top-#{nth($color_names, $i)} {
      border-top: 1px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-bottom-#{nth($color_names, $i)} {
      border-bottom: 1px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-2x-#{nth($color_names, $i)} {
      border: 2px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-top-2x-#{nth($color_names, $i)} {
      border-top: 2px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-right-2x-#{nth($color_names, $i)} {
      border-right: 2px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-bottom-2x-#{nth($color_names, $i)} {
      border-bottom: 2px dashed nth($border-colors, $i) !important;
    }

    .dashed-border-left-2x-#{nth($color_names, $i)} {
      border-left: 2px dashed nth($border-colors, $i) !important;
    }

    // DASHED BORDER CSS END

    .border-top-#{nth($color_names, $i)} {
      border-top: 1px solid nth($border-colors, $i);
    }

    .border-bottom-#{nth($color_names, $i)} {
      border-bottom: 1px solid nth($border-colors, $i);
    }

    .border-left-#{nth($color_names, $i)} {
      border-left: 1px solid nth($border-colors, $i);
    }

    .border-right-#{nth($color_names, $i)} {
      border-right: 1px solid nth($border-colors, $i);
    }

    /************BORDER 2 Px Solid css*************/

    .border-2x-#{nth($color_names, $i)} {
      border: 2px solid nth($border-colors, $i);
    }

    .border-top-2x-#{nth($color_names, $i)} {
      border-top: 2px solid nth($border-colors, $i);
    }

    .border-bottom-2x-#{nth($color_names, $i)} {
      border-bottom: 2px solid nth($border-colors, $i);
    }

    .border-left-2x-#{nth($color_names, $i)} {
      border-left: 2px solid nth($border-colors, $i);
    }

    .border-right-2x-#{nth($color_names, $i)} {
      border-right: 2px solid nth($border-colors, $i);
    }

    /************BORDER 3 Px Solid css*************/

    .border-3x-#{nth($color_names, $i)} {
      border: 3px solid nth($border-colors, $i);
    }

    .border-top-3x-#{nth($color_names, $i)} {
      border-top: 3px solid nth($border-colors, $i);
    }

    .border-bottom-3x-#{nth($color_names, $i)} {
      border-bottom: 3px solid nth($border-colors, $i);
    }

    .border-left-3x-#{nth($color_names, $i)} {
      border-left: 3px solid nth($border-colors, $i);
    }

    .border-right-3x-#{nth($color_names, $i)} {
      border-right: 3px solid nth($border-colors, $i);
    }
  }
}

@mixin setResponsiveFont {
  @for $i from 0 through 500 {
    $font-size: $i * 0.10rem;

    // Montessarat Normal
    .fmrt#{$i} {
      @include applyFont($montserrat, 100, $font-size, $normal-font-style );
    }

    .fmret#{$i} {
      @include applyFont($montserrat, 200, $font-size, $normal-font-style );
    }

    .fmrl#{$i} {
      // @include applyFont($montserrat, 300, $font-size, $normal-font-style );
      @include applyFont($montserrat-light, 300, $font-size, $normal-font-style);
    }

    .fmrn#{$i} {
      // @include applyFont($montserrat, 400, $font-size, $normal-font-style);
      @include applyFont($montserrat, 400, $font-size, $normal-font-style);
    }

    .fmrm#{$i} {
      // @include applyFont($montserrat, 500, $font-size, $normal-font-style);
      @include applyFont($montserrat-medium, 500, $font-size, $normal-font-style);
    }

    .fmrsb#{$i} {
      // @include applyFont($montserrat, 600, $font-size, $normal-font-style);
      @include applyFont($montserrat-semibold, 600, $font-size, $normal-font-style);
    }

    .fmrb#{$i} {
      // @include applyFont($montserrat, 700, $font-size, $normal-font-style);
      @include applyFont($montserrat-bold, 700, $font-size, $normal-font-style);
    }

    .fmreb#{$i} {
      // @include applyFont($montserrat, 800, $font-size, $normal-font-style);
      @include applyFont($montserrat-extrabold, 800, $font-size, $normal-font-style);
    }

    .fmr_black#{$i} {
      @include applyFont($montserrat, 900, $font-size, $normal-font-style);
    }
  }

  @for $i from 1 through 100 {
    $font-size: $i * 0.0625rem;

    // Montessarat Normal
    .fm-l-#{$i}px {
      @include applyFont($montserrat-light, 300, $font-size, $normal-font-style);
    }

    .fm-n-#{$i}px {
      @include applyFont($montserrat, 400, $font-size, $normal-font-style);
    }

    .fm-m-#{$i}px {
      @include applyFont($montserrat-medium, 500, $font-size, $normal-font-style);
    }

    .fm-sb-#{$i}px {
      @include applyFont($montserrat-semibold, 600, $font-size, $normal-font-style);
    }

    .fm-b-#{$i}px {
      @include applyFont($montserrat-bold, 700, $font-size, $normal-font-style);
    }

    .fm-eb-#{$i}px {
      @include applyFont($montserrat-extrabold, 800, $font-size, $normal-font-style);
    }
  }
}